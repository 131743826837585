/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import $ from 'jquery';
import 'bootstrap';
import './styles/app.css';
import 'owl.carousel';
import 'jquery.cookie';

$(function() {
  $('.click_popin').on('click', function (e) { 
    $('#modalPack').modal('show');
  })
})

$(document).on('click',".navbar-toggler.col-up", function() {
  $(this).toggleClass("hide");
  $(this).toggleClass("show");
});

$(".section-ref .nav-item a.active").each(function() {

if ($(this).hasClass("active")) {
  $(this).parent().css("color" , "#ED5F11");
}

});  



function reveal() {
var reveals = document.querySelectorAll(".reveal");

for (var i = 0; i < reveals.length; i++) {
  var windowHeight = window.innerHeight;
  var elementTop = reveals[i].getBoundingClientRect().top;
  var elementVisible = 150;

  if (elementTop < windowHeight - elementVisible) {
    reveals[i].classList.add("active");
  } else {
    reveals[i].classList.remove("active");
  }
  for (let i = 0; i < divs.length; i++) {
    // Check if the div element has the classes 'reveal' and 'active'
    if (divs[i].classList.contains('reveal') && divs[i].classList.contains('active')) {
      $('.reveal.active .section-avantage').addClass("fade-top");
      $('.reveal.active .section-nos-solution').addClass("fade-top");
      $('.reveal.active .section-footer').addClass("fade-top");
      $('.video-container').addClass("fade-left");
      $('.text-win-win').addClass("active");
      $('.reveal.active .section-benefices').addClass("fade-top");
      $('.reveal.active .section-experience button.owl-prev').addClass("active");
      $('.reveal.active .section-experience button.owl-next').addClass("active");
      $('.reveal.active .section-experience-content').addClass("active");
      $('.reveal.active .section-outil-technologique').addClass("fade-top");
      $('.reveal.active .section-outil-technologique-1').addClass("fade-top");
      $('.reveal.active .section-solution').addClass("fade-top");
      $('.reveal.active.section-contact').addClass("fade-top");
      $('.reveal.active .img-mobile.wp').addClass("fade-right");
      $('.reveal.active .box-downsection-with-background').addClass("fade-top");
      $('.reveal.active .carrousel-box').addClass("fade-top");
      $('.reveal.active .section-solution-en-situation').addClass("fade-top");
      $('.reveal.active .section-cartographie h2').addClass("fade-bottom");
      $('.reveal.active .section-cartographie .row').addClass("fade-top");
      $('.reveal.active .box-downsection-with-background-data').addClass("fade-left");
      $('.reveal.active .box-win').addClass("fade-left");
      $('.reveal.active .section-inscription').addClass("fade-top");
      $('.reveal.active .section-box-we').addClass("fade-top");
      $('.reveal.active .section-bien_plus_encore').addClass("fade-top");
      $('.reveal.active .box-downsection-reactivite').addClass("fade-top");
      $('.reveal.active .section-pedagogier .img-inscription').addClass("fade-top");
      $('.reveal.active .box-downsection-expertice').addClass("fade-left");
      $('.reveal.active .section-accompagnement').addClass("fade-top");
      $('.reveal.active .carrousel-box-wp .item').addClass("active");
      $('.section-ref').addClass("fade-top");
      $('.section-contact-page').addClass("fade-top");
      
      

      console.log('Div with class "reveal active" found!');
      break;
    }
  }
}
}

window.addEventListener("scroll", reveal);


  // Get all the div elements on the page
  const divs = document.querySelectorAll('div');

  // Loop through each div element
  for (let i = 0; i < divs.length; i++) {
    // Check if the div element has the classes 'reveal' and 'active'
    if (divs[i].classList.contains('reveal') && divs[i].classList.contains('active')) {
      // Do something here if the div has the classes 'reveal' and 'active'
      console.log('Div with class "reveal active" found!');
      break; // exit the loop once a matching div is found
    }
  }



var owlCarousel = $('.carrousel-notre-solution .owl-carousel');
var itemCount = $('.carrousel-notre-solution .owl-carousel .item').length;

owlCarousel.owlCarousel({
items: 1,
nav: false,
dots: itemCount > 4, // afficher les dots uniquement si itemCount est supérieur à 4
loop: false,
margin: 0,
center: itemCount <= 2,
slideBy: 4, // Ajout de la propriété slideBy
responsive:{
  0:{
      items:1,
      nav: false, // ne pas afficher les boutons de navigation sur la partie mobile
  },
  600:{
      items:1,
      nav: itemCount >= 5, // afficher les boutons de navigation à partir de 5 items sur les écrans supérieurs à 600px
  },
  1000:{
      items:4,
      nav: itemCount >= 5, // afficher les boutons de navigation à partir de 5 items sur les écrans supérieurs à 1000px
  }
}
});


// Affichage des boutons de navigation
if (itemCount >= 5) {
owlCarousel.find('.owl-nav').css('display', 'block');
} else {
owlCarousel.find('.owl-nav').css('display', 'none');
}
// // Ajouter la classe no-transition à tous les dots
// owlCarousel.find('.owl-dot').addClass('no-transition');

// // Supprimer la classe no-transition pour les dots qui contiennent actuellement des owl-item actives
// owlCarousel.on('changed.owl.carousel', function(event) {
//   owlCarousel.find('.owl-dot').addClass('no-transition');
//   var activeIndex = event.item.index;
//   owlCarousel.find('.owl-dot').eq(activeIndex/4).removeClass('no-transition');
// });


// if (itemCount > 4) {
//   $('.section-solution .owl-stage .owl-item.active').addClass('max-width-40');
// } else {
//   $('.section-solution .owl-stage .owl-item.active').addClass('max-width-50');
// }

// owlCarousel.on('changed.owl.carousel', function(event) {
//   owlCarousel.find('.owl-dot').addClass('no-transition');
//   var activeIndex = event.item.index;
//   owlCarousel.find('.owl-dot').eq(activeIndex/4).removeClass('no-transition');

//   // Appliquer la règle CSS pour max-width
//   var currentCount = owlCarousel.find('.owl-item.active').length;
//   if (currentCount = 4) {
//     $('.section-solution .owl-stage .owl-item.active').removeClass('max-width-50').addClass('max-width-40');
//   } else {
//     $('.section-solution .owl-stage .owl-item.active').removeClass('max-width-40').addClass('max-width-50');
//   }
// });
$(document).ready(function() {
// Vérifier la largeur de l'écran
if ($(window).width() < 768) {
// Cacher les paragraphes au chargement de la page
$(".prg4.hide-in-mobile, .prg5.hide-in-mobile, .prg6.hide-in-mobile, .prg7.hide-in-mobile, .prg8.hide-in-mobile").hide();

// Fonction pour afficher/masquer les paragraphes
function toggleParagraph(paragraphClass) {
$("." + paragraphClass).slideToggle();
}

// Gérer les événements click sur les boutons
$(".accordien4").click(function() {
toggleParagraph("prg4");
});
$(".accordien5").click(function() {
toggleParagraph("prg5");
});
$(".accordien6").click(function() {
toggleParagraph("prg6");
});
$(".accordien7").click(function() {
toggleParagraph("prg7");
});
$(".accordien8").click(function() {
toggleParagraph("prg8");
});
}
});


$('.carrousel-box .owl-carousel').owlCarousel({
loop:false,
margin:10,
nav:false,
responsive:{
  0:{
      items:1

  },
  600:{
      items:1
  },
  1000:{
      items:3
  }
}
})
//carrousel-experience
$('.carrousel-experience .owl-carousel').owlCarousel({
loop:true,
margin:10,
nav:false,
responsive:{
0:{
    items:1

},
600:{
    items:1
},
1000:{
    items:1
}
}
})




/* owl corrousel mobile */

if ( $(window).width() < 992 ) {

$('.carrousel-box-wp .owl-carousel').owlCarousel({
  loop:false,
  margin:10,
  nav:false,
  responsive:{
      0:{

          items:1

      },

      992:{

          items:1

      }
  }
}),

$('.owl-carousel-wfo').owlCarousel({

loop:false,

margin:10,

items:1,

responsive:{

  0:{

      items:1

  },

  992:{

      items:3

  }

}

});



const triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'));
triggerTabList.forEach((triggerEl) => {
const tabTrigger = new mdb.Tab(triggerEl);

triggerEl.addEventListener('click', (event) => {
  event.preventDefault();
  tabTrigger.show();
});
});
}



// form newsletters
$("body").on('submit', ".form_submit", function(e) {
e.preventDefault();
var 
  $this = $(this),
  $form = $this.closest('form'),
  data = $form.serializeArray(),
  action = $this.attr('action'),
  method = $this.attr('method'),
  email = $form.find('input[name="inputmail"]'),

  pattern = new RegExp(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)
;
var isChecked = $("#myCheckbox").is(":checked");

$($this.find('.info-feedback')).css('display', 'none');
$form.find('.email-newsletter').removeClass('input-error')

if(pattern.test(email.val())  && isChecked){

  $.ajax({
      type: method,
      url: action,
      data: data
  }).done(function(data) {
      if(!data.error) {
          email.val('');
          $($this.find('.valid-msg')).css('display', 'block');
      }
      else {
          $($this.find('.invalid-feedback.email-exist')).css('display', 'block');
          $form.find('.email-newsletter').addClass('input-error')
      }
  });
} 

else if ( !isChecked ||  email == null || !pattern.test(email.val()) ){
  $($this.find('.invalid-feedback.invalid-msg')).css('display', 'block');
  $form.find('.email-newsletter').addClass('input-error')
}

});

$(document).ready(function() {
var video = document.getElementById("video");
var playPauseButton = $("#play-pause-btn");

$(video).on("play", function() {
playPauseButton.hide();
});
$(video).on("pause", function() {
  playPauseButton.show();
});

playPauseButton.on("click", function() {
video.play();
});
});


$(document).on("click", ".banner-category", function() {
  
  $(this).attr("open", false);

  if ($(this).hasClass("banner-category-active")){
    $(this).removeClass("banner-category-active");
  }else{
    $(".banner-category-active").removeClass("banner-category-active");
    $(this).addClass("banner-category-active");
  }

});

$(document).on("click", ".play-pause-btn", function() {
  var $videoPlayer =  $(this).prev();
   if($($videoPlayer).attr('poster') == undefined) {   $videoPlayer =  $(this).prev() }

    if ( $videoPlayer.get(0).paused) {
        $(".img-video").fadeOut(500);
        $(".video-text").fadeOut(500);
        $(this).fadeOut(500);
        $(".video-parent-class").addClass("played");
        $videoPlayer.trigger('play');
    }
    else {
        $videoPlayer.trigger('pause');
        $(".video-parent-class").removeClass("played");
        $(this).fadeIn(500);
    }
})

$(document).on("click", ".size-lang", function(e) {
    e.preventDefault();
    var 
        $this = $(this),
        currentHref = $this.data('href'),
        localeCurrent = $this.data('localecurrent'),
        langSelected = $this.data('langselected'),
        route = $this.data('route'),
        slug = $this.data('slug')
    ;
    currentHref = currentHref.replace("/"+localeCurrent,  "/"+langSelected)
    if(route != "front_page_category_show" && route != "front_page_show") {
      window.location = currentHref
    }
    else {
      $.ajax({
        type: 'POST',
        url:  check_value,
        data : {
          'route' : route,
          'slug'  : slug ,
          'localeCurrent' : localeCurrent,
          'langSelected' : langSelected
        }
      }).done(function(data) {
        currentHref = currentHref.replace("/"+slug,  "/"+data)
        window.location = currentHref

      })
    } 
})
 
$(function() {
  $("body").on('click', ".submit_pack", function(e) {
    e.preventDefault();
    var 
      $this = $(this),
      $form = $this.closest('form'),
      data = $form.serializeArray(),
      action = $form.attr('action'),
      method = $form.attr('method'),
      email = $form.find('input[name="inputmailpack"]'),
      societe = $form.find('input[name="inputsocietepack"]'),
      pattern = new RegExp(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)
    ;
    var isChecked = $("#btnCheckModalPack").is(":checked");
    $form.find('.alerte_info_pack_email').css('display', 'none');
    $form.find('.alerte_info_pack_societe').css('display', 'none');
    $form.find('.alerte_info_pack_conditions').css('display', 'none');
    $form.find('.email-modal-pack').removeClass('input-error')
    $form.find('.societe-modal-pack').removeClass('input-error')

    if($form.find("#btnCheckModalPack").length == 0) {
      isChecked = true;
    }

    if(isChecked && pattern.test(email.val()) && $(societe).val() != '' && societe != null){
      $.ajax({
          type: method,
          url: action,
          data: data
      }).done(function(data) {
          if(!data.error) {
              email.val('');
              societe.val('');
              $($this.find('.valid-msg')).css('display', 'block');
              $('.click_popin_link').click()
              var urlLink = $('.click_popin_link').attr('href')
              var a = document.createElement('a');
              a.target="_blank";
              a.href=urlLink;
              a.click();
          }
      });
    } 
    if ( email == null || !pattern.test(email.val()) ){
        $form.find('.alerte_info_pack_email').css('display', 'block');
        $form.find('.email-modal-pack').addClass('input-error')
    }
    if ( $(societe).val() == '' || societe == null  ){
      $form.find('.alerte_info_pack_societe').css('display', 'block');
      $form.find('.societe-modal-pack').addClass('input-error')
    }
    if (!isChecked){
      $form.find('.alerte_info_pack_conditions').css('display', 'block');
    }
  });
})
